import "./style.css";

const Footer = () => {
  return (
    <div className="footer">
      <span className="copy-right">Copyright 2024 © Lucky68</span>
    </div>
  );
};
export default Footer;
